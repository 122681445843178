/* Navbar container styling */
.navbar-bg {
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Logo styling */
  .logo {
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: #000;
  }
  
  /* Navbar link styles */
  ul li {
    border-bottom: 3px solid transparent;
    transition: border-color 0.3s ease-in-out;
  }
  
  ul li:hover {
    border-bottom: 3px solid #ffffff;
  }
  
  .nav-link {
    font-size: 1rem;
    color: #000;
    transition: color 0.3s ease-in-out;
  }
  
  .nav-link:hover {
    color: #007bff;
  }
  
  /* Marquee container styling */
  .marquee-content {
    position: relative;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    color: #ffd700;
    padding-top: 5px;
    font-size: 1.5rem;
    animation: marquee 12s linear infinite;
    display: inline-block;
  }

  .marquee-content.paused {
    animation-play-state: paused;
  }
  
  /* Marquee animation */
  @keyframes marquee {
    0% {
      transform: translateX(100%); /* Starts at the visible area */
    }
    100% {
      transform: translateX(-100%); /* Moves completely out of the view */
    }
  }
  
  /* Adjust marquee for better readability */
  .marquee-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: #007bff;
  }
  
  /* Navbar shadow for modern look */
  .nav-style {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  
  /* Responsive styling for larger tablets */
  @media (max-width: 992px) {
    .navbar-brand img {
      width: 50px;
      height: 50px;
    }
    .navbar-brand span {
      font-size: 1.5rem;
    }
    .navbar-nav .nav-link {
      font-size: 0.9rem;
    }
    .marquee-content{
        font-size: 1.1rem;
    }
  }
  
  /* Responsive styling for tablets */
  @media (max-width: 768px) {
    .navbar-brand img {
      width: 40px;
      height: 40px;
    }
    .navbar-brand span {
      font-size: 1.25rem;
    }
    .navbar-nav .nav-link {
      font-size: 0.85rem;
    }
    .marquee-content {
        
        font-size: 0.9rem;
       
        animation-duration: 16s; 
      }
  }
  
  /* Responsive styling for mobile devices */
  @media (max-width: 576px) {
    .navbar-brand img {
      width: 30px;
      height: 30px;
    }
    .navbar-brand span {
      font-size: 1rem;
    }
    .navbar-nav .nav-link {
      font-size: 0.75rem;
    }
    .marquee-content {
        font-size: 0.6rem;
        padding: 5px 0;
        animation-duration: 18s;
      }
  }