/* .table-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1000px;
    overflow-x: auto; 
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; 
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 14px; 
}

.table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    .table-container {
        padding: 10px;
    }

    .table th,
    .table td {
        font-size: 12px; 
        padding: 6px; 
    }
}

@media (max-width: 576px) {
    h2 {
        font-size: 1.5rem;
    }

    .table th,
    .table td {
        font-size: 10px;
        padding: 4px; 
    }


    .table th {
        display: none;
    }

    .table td {
        display: block;
        text-align: right;
        position: relative;
        padding-left: 50%;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 45%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
    }
} */


.invoice-list{
    width: 200px;
    margin-left: 40%;
}

.table-container {
    margin: 20px;
    padding: 10px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th, .table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .table th, .table td {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  
    .table-container h2 {
      font-size: 18px; /* Adjust heading size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .table th, .table td {
      font-size: 10px; /* Further adjust font size for very small screens */
    }
  
    .table-container h2 {
      font-size: 16px; /* Further adjust heading size for very small screens */
    }
  }
  