.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-content {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

section {
  position: fixed;
  top: 200px;
  left: 1px;
  z-index: 2;
}

section div {
  background-color: rgba(46, 11, 246, 0.2);
  width: 120px;
  padding: 5px;
  color: white;
  margin-bottom: 20px;
  border-radius: 20px;
  transition: 0.2s;
}

section div:hover {
  background-color: yellow;
  color: red;
  width: 150px;
  cursor:alias;
}

.hero-word {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 4vw;
  overflow: hidden;
  font-family:Georgia, 'Times New Roman', Times, serif;
}
.wel{
  font-family: 'Courier New', Courier, monospace;
}

.hero-word-span {
  display: inline-block;
  animation: slideIn 0.5s forwards;
  font-size: 5vw;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero-word-span {
  display: inline-block;
  animation: slide 1s forwards;
  animation-delay: 0.5s;
}

.hero-word .wel {
  display: inline-block;
  animation: slideIn 1s forwards;
}

@keyframes slide {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero-word .wel {
  display: inline-block;
  animation: slideIn 1s forwards;
  animation-delay: 0.5s;
}

@media (max-width: 768px) {
  .hero-word {
    font-size: 6vw;
  }

  section {
    top: 150px;
    left: 5px;
  }

  section div {
    width: 120px;
    padding: 8px;
  }

  section div:hover {
    width: 140px;
  }
}

@media (max-width: 576px) {
  .hero-word {
    font-size: 8vw; /* Further increase font size for very small screens */
  }

  section {
    top: 20vh;
    left: 2px;
  }

  section div {
    width: 100px;
    padding: 3px;
    background-color: rgba(46, 11, 246, 0.2);
  }

  section div:hover {
    width: 120px;
  }
}