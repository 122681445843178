  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.footer .social-links a {
    font-size: 1.5rem;
    transition: color 0.3s ease;
    margin: 10px;
    background-color: white;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

.footer .social-links a:hover {
    background-color: #007bff;
    color: white;
}

.footer-copy {
    text-align: center;
}

@media (max-width: 768px) {
    .footer-copy {
        flex-direction: column;
    }

    .footer .social-links a {
        font-size: 1.25rem;
        width: 35px;
        height: 35px;
        line-height: 35px;
    }
}

@media (max-width: 576px) {
    .footer .social-links a {
        font-size: 1rem;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}





/* .footerr {
    background-color: var(--secondary-color);
    color: var(--background-color);
    text-align: center;
    padding: 10px;
  } */
  