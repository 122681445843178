.login video{
    position: absolute;
    z-index: -99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.login{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(150deg, rgba(219,40,250,1) 18%, rgba(66,73,243,1) 62%, rgba(69,252,155,1) 100%);
}