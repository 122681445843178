
  .why-choose-us img {
    width: 100%;
    border-radius: 8px;
  }

  .description{
    border: 2px solid rgb(243, 87, 44);
    padding: 10px;
    border-radius: 25px;
  }
  
  .feature-box {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .feature-box .icon {
    font-size: 3rem;
    color: #007bff;
  }
  
  .feature-box h4 {
    margin-top: 15px;
    font-weight: bold;
  }
  
  .feature-box p {
    margin-top: 10px;
    color: #555;
  }
  



   /* .about-container {
    padding: 50px;
    background-color: #f9f9f9;
  } */
  
  .title {
    font-size: 2em;
    margin-top: 5%;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }

  .title-about{
    font-size: 2em;
    /* margin-top: 2%; */
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .description {
    font-size: 1.2em;
    margin-bottom: 30px;
    text-align: center;
    animation: fadeIn 2s ease-in-out;
  }
  
  .why-choose-section, .features-section, .team-section, .history-section {
    margin: 50px 0;
  }
  
  .reasons-list, .features-list, .team-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .reason-item, .feature-item, .team-member {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .reason-item:hover, .feature-item:hover, .team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .team-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-left: 50px;
  }
  
  .team-name {
    font-size: 1.1em;
    text-align: center;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }  






.about-container {
  background-color: var(--background-color);
  color: var(--secondary-color);
  padding: 20px;
}

.about-header {
  color: var(--blue-color); /* Applying the new blue color */
  text-align: center;
}

.about-text {
  color: var(--blue-color); /* Applying the new blue color */
}
