.gallery-container {
    padding: 20px;
}

.gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
}

.g-img {
    margin: 10px;
    border-radius: 25px;
    box-shadow: -2px 2px 10px 2px;
    border: 2px double #007bff;
    transition: all 0.3s ease;
    /* width: 100%; */
    width: 200px;
    height: 350px;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.g-img:hover{
    transform: scale(1.1);
  filter: brightness(0.9);
}


.marquee-content {
    color: yellow;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 10s linear infinite;
  }
  
  .marquee-content.paused {
    animation-play-state: paused; /* Pauses animation on hover */
  }


  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

@media (min-width: 1200px) {
    .g-img {
        width: 30%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .g-img {
        width: 45%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .g-img {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .g-img {
        width: 100%;
    }
}








.gallery-container {
    background-color: var(--background-color);
    color: var(--secondary-color);
    padding: 20px;
  }
  
  .gallery-header {
    color: var(--blue-color); /* Applying the new blue color */
    text-align: center;
  }
  
  .gallery-image {
    border: 2px solid var(--blue-color); /* Applying the new blue color for border */
  }
  
